var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"supplier"},[_c('div',{staticClass:"title"},[_c('el-button',{staticClass:"mb10 bkcyan bdcyan",attrs:{"type":"primary","icon":"el-icon-success"},on:{"click":_vm.goAdd}},[_vm._v("新增")])],1),_c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list}},[_c('el-table-column',{attrs:{"prop":"name","min-width":"200","label":"活动名称"}}),_c('el-table-column',{attrs:{"prop":"giveDay","label":"赠送天数"}}),_c('el-table-column',{attrs:{"prop":"plusPrice","label":"叠加价格"}}),_c('el-table-column',{attrs:{"prop":"startTime","label":"开始时间","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("timeToDate")(row.startTime))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"endTime","label":"截止时间","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("timeToDate")(row.endTime))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"availNum","label":"可参与次数","min-width":"200"}}),_c('el-table-column',{attrs:{"prop":"createTime","label":"创建时间","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("timeToDate")(row.createTime))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"city","label":"操作","min-width":"200","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isExpired === 0)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.goEdit(row)}}},[_vm._v("编辑")]):_vm._e(),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.del(row)}}},[_vm._v("删除")])]}}])})],1)],1),_c('div',{staticClass:"fr mt20"},[_c('el-pagination',{attrs:{"current-page":_vm.beginIndex,"page-size":_vm.pageSize,"layout":"total, prev, pager, next","total":_vm.total},on:{"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.beginIndex=$event},"update:current-page":function($event){_vm.beginIndex=$event}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }