<template>
  <div>
    <el-form :model="searchForm" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="活动名称" prop="name">
        <el-input
          v-model="searchForm.name"
          style="width: 300px"
          :maxlength="120"
          placeholder="请输入活动名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="赠送天数" prop="giveDay">
        <el-input-number
          v-model="searchForm.giveDay"
          :min="1"
          style="width: 200px"
        ></el-input-number>
        <span class="ml10">天</span>
      </el-form-item>
      <el-form-item label="叠加价格" prop="plusPrice">
        <el-input
          v-model="searchForm.plusPrice"
          style="width: 300px"
          placeholder="请输入叠加价格"
        ></el-input>
      </el-form-item>
      <el-form-item label="活动有效期" prop="time">
        <el-date-picker
          v-model="searchForm.time"
          clearable
          style="width: 400px"
          value-format="timestamp"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          align="right"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="用户可参与次数" prop="availNum">
        <el-input-number
          v-model="searchForm.availNum"
          :min="1"
          style="width: 200px"
        ></el-input-number>
      </el-form-item>

      <!-- <el-form-item class="mt20">
        <el-button type="primary" @click="submitForm" :loading="disabled"
          >确认</el-button
        >
        <el-button @click="goBack">返回</el-button>
      </el-form-item> -->
    </el-form>
    <div>
      <el-table
        @selection-change="handleSelectionChange"
        :data="insuranceList"
        style="width: 100%"
        ref="multipleTable"
      >
        <!-- :row-key="getRowKeys" -->
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="agentName" label="代理商"> </el-table-column>
        <el-table-column prop="storeName" label="门店"> </el-table-column>
        <el-table-column prop="commodityType" label="商品类型" min-width="130">
          <template slot-scope="{ row }">
            {{ $changeObj(PRODUCT_TYPE)[row.commodityType] }}
          </template>
        </el-table-column>
        <el-table-column prop="specification" label="商品规格">
        </el-table-column>
        <!-- <el-table-column prop="cashPledge" label="押金"> </el-table-column>
        <el-table-column prop="singleRent" label="单期租金" min-width="150">
        </el-table-column>
        <el-table-column prop="validDay" label="有效时长" min-width="150">
        </el-table-column> -->
        <el-table-column prop="activityName" min-width="200" show-overflow-tooltip label="已绑定活动" >
        </el-table-column>
      </el-table>
    </div>
    <div style="margin: 50px 0 0 0;">
      <el-button type="primary" @click="submitForm" :loading="disabled"
        >确认</el-button
      >
      <el-button type="info" @click="goBack">返回</el-button>
    </div>
  </div>
</template>

<script>
import Reg from "@/js/reg";
import { timeToDate } from "@/utils/date-format";
import { addRecharge,  updatedActive} from "@/api/marketing";
import { getGoodsList ,queryActivityDetail } from "@/api/goods";
import { PRODUCT_TYPE } from "@/js/constant";

export default {
  data() {
    const checkPrice = (rule, value, callback) => {
     if (!Reg.isDigit(value)) {
        callback(new Error("请输入正确价格!"));
      } else {
        callback();
      }
    };
    return {
      searchForm: {
        name: "",
        giveDay: 1,
        plusPrice: "",
        availNum: 1,
        time: ""
      },
      rules: {
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
        giveDay: [
          { required: true, message: "请输入赠送天数", trigger: "blur" }
        ],
        plusPrice: [{ validator: checkPrice, trigger: "blur" },  {required: true, message: "请输入叠加价格", trigger: "blur" }],
        time: [{ required: true, message: "请选择时间", trigger: "change" }],
        availNum: [
          { required: true, message: "请输入参与次数", trigger: "blur" }
        ]
      },
      startTime: null,
      endTime: null,
      disabled: false,
      /*管理列表*/
      insuranceList: [],
      PRODUCT_TYPE,
      arr:[],
      selectinsuranceList:[]
    };
  },
  mounted() {
    if (this.$route.query.type == "edit") {
      const {
        name,
        giveDay,
        plusPrice,
        availNum,
        startTime,
        endTime,
        number
      } = this.$route.query;
      this.searchForm = {
        name,
        giveDay,
        plusPrice: plusPrice || "",
        availNum,
        time: [startTime, endTime],
        number
      };
      this.queryActivityDetail();
    }
    this.getGoodsList();
  },
  methods: {
    getRowKeys(row) {
      return row.productNo;
    },
    async queryActivityDetail(){
      const params = {
         number:this.searchForm.number
      }
      const res = await queryActivityDetail(params);
      this.arr = res.ldata;

    },
    handleSelectionChange(row) {
      console.log(row,'row------');
      this.selectinsuranceList = row;
    },
    /**获取管理列表 */
    async getGoodsList() {
      const params = {
        beginIndex: 0,
        pageSize: 500
      };
      const res = await getGoodsList(params);
      this.insuranceList = res.ldata;
      //
      let arr = [];
      this.arr.forEach(el=>{
        this.insuranceList.forEach(el2=>{
          if(el2.commodityNo == el){
            arr.push(el2)
          }
        })
      })
       this.$nextTick(()=>{
         arr.forEach(el => {
        this.$refs.multipleTable.toggleRowSelection(el);
      })
       })
    //
    },
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if(!this.selectinsuranceList.length){
            this.$message.error('请勾选关联商品')
            return;
          }
          if (this.disabled) {
            return;
          }
          this.disabled = true;
          if (this.searchForm.time) {
            this.startTime = this.searchForm.time[0];
            this.endTime = this.searchForm.time[1];
          } else {
            this.startTime = null;
            this.endTime = null;
          }
          const params = {
            ...this.searchForm,
            startTime: this.startTime,
            endTime: this.endTime
          };
          if (!this.endTime || !this.startTime) {
            params.startTime = null;
            params.endTime = null;
          }
          params.productNos =  this.selectinsuranceList.map(ele => ele.commodityNo).join(',')
          delete params.time;
          try {
            if ( this.$route.query.type == "edit") {
              await updatedActive(params)
            } else {
              await addRecharge(params);
            }

            this.disabled = false;
            let tips = this.$route.query.type == "edit" ? "编辑成功" : "新增成功";
            this.$message.success(tips);
            setTimeout(() => {
              this.$router.push("/leaseholdMgmt/recharge");
            }, 1000);
          } catch (error) {
            this.disabled = false;
          }
        } else {
          return false;
        }
      });
    },
    goBack() {
      this.$router.push('/leaseholdMgmt/recharge');

    }
  },
  components: {}
};
</script>

<style scoped></style>
