import axios from "../js/ajax";
/** 充值赠送列表 */
export const getRechargeList = params =>
  axios.get("/electricity/lease/activity/queryNotDelete", {
    params,
    loading: true
  });
/* 删除充值赠送 */
export const delEditRecharge = params =>
  axios.post("/electricity/lease/activity/update", params);
/** 新增充值赠送 */
export const addRecharge = params =>
  axios.get("/electricity/lease/activity/insert", {
    params,
    loading: true
  });

/** 新活动弹窗列表 */
export const getMarketingActivityList = params =>
  axios.get("/managementPlatform/admin/getHaiLiMarketingActivityList", {
    params,
    loading: true
  });
/* 删除活动弹窗 */
export const deleteMarketingActivity = params =>
  axios.post("/managementPlatform/admin/deleteMarketingActivity", params);

/* 新增/编辑活动弹窗 */
export const createOrUpdateMarketingActivity = params =>
  axios.post(
    "/managementPlatform/admin/createOrUpdateMarketingActivity",
    params
  );

  export const updatedActive =  params => axios.get('/electricity/lease/activity/update', {
    params,
    loading: true
  })
