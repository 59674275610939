<template>
  <el-form
    class="form"
    ref="form"
    :model="form"
    :rules="rules"
    label-width="150px"
  >
    <el-form-item label="活动名称" prop="name">
      <el-input
        v-model="form.name"
        clearable
        placeholder="请输入活动名称"
        style="width: 300px"
      ></el-input>
    </el-form-item>
    <el-form-item label="图片浏览" prop="imageSrc">
      <el-upload
        action=""
        :show-file-list="false"
        :auto-upload="false"
        accept="image/*"
        :on-change="handleUpload"
      >
        <el-button size="small" type="primary">{{
          imageSrc ? "重新选择" : "上传图片"
        }}</el-button>
      </el-upload>
      <div v-if="imageSrc" class="img-wrap">
        <el-image :src="imageSrc" style="width: 300px" fit="fill" alt="" />
        <i
          class="el-icon-circle-close"
          style="font-size: 20px"
          @click="imageSrc = ''"
        ></i>
      </div>
    </el-form-item>
    <el-form-item label="启用状态" prop="enableStatus">
      <el-select
        v-model="form.enableStatus"
        clearable
        placeholder="请选择启用状态"
        style="width: 300px"
      >
        <el-option
          v-for="opt in ENABLE_STATUS"
          :key="opt.value"
          :value="opt.value"
          :label="opt.label"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item class="mt20">
      <el-button type="primary" @click="submitForm" :loading="disaled"
        >确认</el-button
      >
      <el-button type="info" @click="goBack">返回</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { createOrUpdateMarketingActivity } from "@/api/marketing";
import { ENABLE_STATUS } from "@/js/constant";


export default {
  data() {
    return {
      ENABLE_STATUS,
      form: {
        type: "2",
        name: "",
        imageSrc: "",
        enableStatus: "",
      },
      rules: {
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
        imageSrc: [{ required: true, message: "请选择图片", trigger: "blur" }],
        unitNo: [
          { required: true, message: "请选择门店", trigger: "change" }
        ],
        enableStatus: [
          { required: true, message: "请选择启用情况", trigger: "change" }
        ]
      },
      props: {
        value: "unitNo",
        label: "name",
        checkStrictly: false,
      },
      disaled: false,
      imageSrc: "",
      slectUnitNos: ""
    };
  },
  watch: {
    imageSrc: function(imageSrc) {
      let formData = { ...this.form };
      formData.imageSrc = imageSrc;
      this.form = formData;
    },
  },
  mounted() {
    if (this.$route.query.type == "edit") {
      const imageSrc = sessionStorage.getItem("imageSrc");
      this.imageSrc = imageSrc;
      this.slectUnitNos = this.$route.query.unitNo;
      this.form.enableStatus = this.$route.query.enableStatus;
      this.form.name = this.$route.query.name;
    }
  },
  methods: {
    /** 获取代理商 */
    handleUpload(file) {
      let fileReader = new FileReader();
      fileReader.onloadend = e => {
        this.imageSrc = e.target.result;
      };
      fileReader.readAsDataURL(file.raw);
    },
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          // if(this.form.unitNo.length <= 1) {
          //   this.$message.error('请选择门店')
          //   return
          // }
          this.disaled = true;
          // const unitNo = this.form.unitNo[this.form.unitNo.length-1]
          const params = { ...this.form, unitNo: '1606790724195850498' };
          if (this.$route.query.type == "edit") {
            params.number = this.$route.query.number;
          }
          try {
           await createOrUpdateMarketingActivity(params);
            this.disaled = false;
            this.$message.success("操作成功");
            setTimeout(() => {
              this.$router.push("/leaseholdMgmt/activity");
            }, 1000);
          } catch (error) {
            console.log(error);
            this.disaled = false;
          }
        } else {
          return false;
        }
      });
    },
    goBack() {
      this.$router.push('/leaseholdMgmt/activity');

    }
  },
  components: {
  }
};
</script>

<style scoped>
.img-wrap {
  width: 400px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
