<template>
  <div class="supplier">
    <div class="title">
      <el-button type="primary" class="mb10 bkcyan bdcyan" @click="goAdd" icon="el-icon-success">新增</el-button>
    </div>
    <div>
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="name" min-width="200" label="活动名称">
        </el-table-column>
        <el-table-column prop="giveDay" label="赠送天数"> </el-table-column>
        <el-table-column prop="plusPrice" label="叠加价格"> </el-table-column>
        <el-table-column prop="startTime" label="开始时间" width="220">
          <template slot-scope="{ row }">
            {{ row.startTime | timeToDate }}
          </template>
        </el-table-column>
        <el-table-column prop="endTime" label="截止时间" width="220">
          <template slot-scope="{ row }">
            {{ row.endTime | timeToDate }}
          </template>
        </el-table-column>
        <el-table-column prop="availNum" label="可参与次数" min-width="200"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="220">
          <template slot-scope="{ row }">
            {{ row.createTime | timeToDate }}
          </template>
        </el-table-column>
        <el-table-column prop="city" label="操作" min-width="200"  fixed='right'>
          <template slot-scope="{ row }" >
            <el-button
              type="text"
              v-if="row.isExpired === 0"
              @click="goEdit(row)"
              >编辑</el-button
            >
            <el-button type="text" @click="del(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getRechargeList, delEditRecharge } from "@/api/marketing";
export default {
  data() {
    return {
      list: [],
      pageSize: 20,
      beginIndex: 1,
      total: 0
    };
  },
  mounted() {
    this.getRechargeList();
  },
  methods: {
    /** 获取 供应商列表 */
    async getRechargeList() {
      let page = this.beginIndex;
      page = page - 1;
      const params = {
        pageSize: this.pageSize,
        beginIndex: page
      };
      const res = await getRechargeList(params);
      this.list = res.ldata;
      this.total = res.mdata.total;
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.getRechargeList();
    },
    /**删除 */
    del(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const params = {
            number: row.number,
            deleteFlag: 1
          };
          await delEditRecharge(params);
          this.$message.success("删除成功");
          this.beginIndex = 1;
          this.getRechargeList();
        })
        .catch(() => {});
    },
    goAdd() {
      this.$store.dispatch('login/checkHandle', '/electricity/lease/activity/insert' ).then((flag) => {
        if(flag) {
          this.$router.push("/leaseholdMgmt/addRecharge");
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push("/leaseholdMgmt/addRecharge");
      })
      
    },
    goEdit(row) {
      this.$store.dispatch('login/checkHandle', '/electricity/lease/activity/update' ).then((flag) => {
        if(flag) {
          this.$router.push({
            path: "/leaseholdMgmt/addRecharge",
            query: {
              name: row.name,
              giveDay: row.giveDay,
              startTime: row.startTime,
              type: "edit",
              endTime: row.endTime,
              availNum: row.availNum,
              plusPrice: row.plusPrice,
              number: row.number
            }
          });
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push({
          path: "/leaseholdMgmt/addRecharge",
          query: {
            name: row.name,
            giveDay: row.giveDay,
            startTime: row.startTime,
            type: "edit",
            endTime: row.endTime,
            availNum: row.availNum,
            plusPrice: row.plusPrice,
            number: row.number
          }
      });
      })
      
    }
  },
  components: {}
};
</script>

<style scoped lang="less">
.supplier {
  padding-bottom: 80px;
}
</style>
